// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo__6yrBi {
  width: 250px;
  margin: -7px 0 5px;
}

.Logo_miniLogo__5RSbB {
  width: 100%;
  height: 32px;
  object-fit: contain;
  object-position: left;
}

@media (min-width: 576px) {
  .Logo_miniLogo__5RSbB {
    height: 100%;
    max-height: 70px;
  }
}
[data-community=dc] .Logo_miniLogo__5RSbB {
  height: 40px;
}
@media (min-width: 576px) {
  [data-community=dc] .Logo_miniLogo__5RSbB {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 576px) {
  [data-community=ydn] .Logo_miniLogo__5RSbB {
    margin-left: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,qBAAA;AACF;;AAEA;EACE;IACE,YAAA;IACA,gBAAA;EACF;AACF;AAGE;EACE,YAAA;AADJ;AAIE;EACE;IACE,WAAA;IACA,YAAA;EAFJ;AACF;;AAOE;EACE;IACE,iBAAA;EAJJ;AACF","sourcesContent":[".logo {\n  width: 250px;\n  margin: -7px 0 5px;\n}\n\n.miniLogo {\n  width: 100%;\n  height: 32px;\n  object-fit: contain;\n  object-position: left;\n}\n\n@media (min-width: 576px) {\n  .miniLogo {\n    height: 100%;\n    max-height: 70px;\n  }\n}\n\n[data-community='dc'] {\n  .miniLogo {\n    height: 40px;\n  }\n\n  @media (min-width: 576px) {\n    .miniLogo {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n\n[data-community='ydn'] {\n  @media (max-width: 576px) {\n    .miniLogo {\n      margin-left: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Logo_logo__6yrBi`,
	"miniLogo": `Logo_miniLogo__5RSbB`
};
export default ___CSS_LOADER_EXPORT___;
